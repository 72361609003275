@import './global.scss';

.app{
    height: 100vh;
     
    
    .navbar{
        z-index: 100;
        overflow: hidden;
        width: 100%;
        
    }
    //navbar and section fit together
    .sections{
        width: 100%;
        height: calc(100vh - 68px) ;
        background-color: white ;
        position: relative;
        scroll-behavior: smooth;
        // scroll-snap-type: y mandatory;
        scrollbar-width: none;//firefox
        &::-webkit-scrollbar {
            display:none;

        

        }

        @include mobile{
            .work{
                height: 220vh !important;
            }
        }

        @include desktop{
            // .work{
            //     height: 200vh !important;
            // }

            // .sections{
            //     height: calc(750px) !important;
            // }
        }


    

        //component widths
        > *{
            width: 100vw;
            height: calc(100vh - 68px) ;
            scroll-snap-align: start;


        }

    }
}

