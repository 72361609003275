@import '../../global.scss';

.portfolio {
    background-color: $secondary;
    // background-image: url('../../../public/images/bigpage2.svg');
    background-size: contain;
    //  background-color: $secondaryLight;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(175vh - 75px) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80%;
    
    
    @include mobile{
        height: calc(300vh - 75px) !important;
    }

    @include desktop{
        height: calc(150vh) !important;
    }

    @include big{
        height: calc(120vh) !important;
    }

    // @include tiny{
    //     height: calc(240vh - 75px) !important;
    // }

    .bumper{
        border-radius: 8px;
        
        padding: 0 1rem;

    }

    h2{
        color: rgb(235, 246, 255);
        margin-top: 2rem;
        font-size: 1.5rem;
        font-weight: 500;

    }
    .line{
        width: 75px;
        height: 3px;
        background-color: rgb(255, 255, 255);
        margin-top: .5rem;
        border-radius: 10px;
        margin-bottom: 1rem;
    }
    h3{
        color: white;
        font-size: 2rem;
        font-weight: 500;
        margin-top: .3rem;
        @include mobile{
            font-size: 1.5rem;
            
        }
    }
    

    ul{
        margin:1rem;
        padding: 0;
        list-style: none;
        display: flex;

    

    }
    .cards{
        display: flex;
        margin-top: 2rem;
        padding: 10px;
        
        @include mobile{
            flex-direction: column;
        }

        .singleCard{
            @include mobile{
            
                margin-top: 2rem;
                padding-bottom: 4px;
            }
        }
    }

    .cards-2{
        display: flex;
        margin-top: 4rem;
        padding: 10px;
        
        @include mobile{
            display: none;
        }
    }

    .container {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;


        .item{
            position: relative;
            width: 30%;
            
            border-radius: 20px;
            border: 1px solid $primaryDark;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.5s ease;

                h3{
                    position: absolute;
                    font-size: 2rem;
                    color:white;
                }

            img{
                width: 100%;
               
                z-index: 2;
            }

            &:hover{
                background-color: #000;
                
                img{
                    opacity: .2;
                    z-index: 0;
                }
            }
        }
    }
}