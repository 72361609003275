@import '../../global.scss';

.work {
    
    color: rgb(255, 255, 255);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../public/images/sub8.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    
    @include mobile{
        background-image: url('../../../public/images/bigpage.svg');
    }

    .line-2{
        width: 75px;
        height: 3px;
        background-color: black;
        margin-top: .69rem;
        border-radius: 10px;
        position: absolute;
        top: 100px;
    }
    .title-2{
        position: absolute;
        top: 65px;
        color: #000;
        font-weight: 500;
        font-size: 2rem;
        @include mobile{
            font-size: 1.5rem;
            
        }
    }
    h2.title{
        margin-top: 2rem;
        color: rgb(140, 140, 140);
        font-size: 1.5rem;
        position: absolute;
        top: 0px;
        font-weight: 500;
    }

    .title-holder{
        display: flex;
        flex-direction: column;
        justify-content: center;
       align-items: center;
    }



    .arrow{
        position: absolute;
        top: 330px;
        font-size: 5rem;
        font-weight: 100;
        color: #000;
        @include mobile{
            display: none;
        }

        &.left{
            left: 50px;
            transform: rotate(180deg);
            cursor: pointer;
        }


        &.right{
            right: 50px;
            cursor: pointer;
        }

        &.disabled{
            display: none;
            cursor: default;
        }
    }



    .slider {
        color: #000;
        height: 400px;
        display: flex;
        position: absolute;
        left:0;
        top: 170px;
        transition: all .75s ease;
        
        @include mobile{
            height: 190vh;
            flex-direction: column;
            justify-content: center;
            
        }

        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            padding: 4px 0;
            .item{
                width: 800px;
                height: 100%;
                border-radius: 20px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                border: 1.5px solid black;
                
                @include mobile{
                    width: 70%;
                    height: 75%;
                    overflow: hidden;
                    margin-bottom: 5rem;
                    margin-top: 1rem;
                }
            }

            .left{
                flex:4;
                background-color: #fff;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include mobile{
                    flex: 8;
                    height: 70%;
                }
                
                .left-container{
                    width: 90%;
                    height: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                
                    .img-content{
                        
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: #000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include mobile{
                            display: none;
                        }
                
                    }
                    .icon{
                        font-size: 2rem;
                        color: #fff;
                        
                    }

                    h2{
                        font-size: 2rem;
                        @include mobile{
                            font-size: 1.1rem;
                            margin-top: 2rem;
                        }
                        @include tiny{
                            font-size: .9rem;
                          
                        }
        
                    }

                    .description{
                        font-size:1rem;
                        color:rgb(46, 46, 46);
                        line-height: 125%;
                        @include mobile{
                            display: none;
                             
                         }
                        
                    }
                    .small_description{
                        display: none;
                        line-height: 125%;
                        @include mobile{
                            display: flex;
                            font-size: .9rem;

                            justify-content: space-between;
                         }

                         @include tiny{
                           
                            font-size: .6rem;

                           
                         }
                    }
                    span{
                        font-size: .9rem;
                        font-weight: 600;
                        text-decoration: underline;
                    }


                
                }


            }

            .right{
                flex:8;
                background-color: rgb(0, 0, 0);
                height: 100%;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                
                img{
                    
                    
                    height: 100%;
                    
                    @include mobile{
                    transform: translateX(-50px);
                    }
    
    
                    
                    
                }
            }

            
        }
    }
}

