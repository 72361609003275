@import '../../global.scss';
.appBar{
    overflow: hidden;
    min-height: 10px !important;
    width: 100%;
}

.MuiAppBar-root{
    overflow: hidden;
    min-height: 10px !important;
    width: 100%;
}

.button:hover{
    color: $secondary;
    background-color: $secondary !important;
}

.button{
    color: #fff !important;
    background-color: rgb(26, 26, 26) !important;
}


.boxContainer{
    color: black !important;
    fill: black;

    .icon{
        font-size: 2rem !important;
        color: black;
    }

    .icon:hover{
        color: $secondary;
    }
}



