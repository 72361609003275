@import '../../../global.scss';


.card {
margin-left: 3rem;
   border-radius: 10px !important;
   
}

a{
    text-decoration: none;
}

.noBtn{
    display: none !important;
}