@import '../../global.scss';

.sidebar{
    width: 20rem;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 2;
    right: -20rem;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        
        width: 80%;

        li{
            display: flex;
            justify-content: center;
           align-items: center;
            font-size: 2.4rem;
            margin-top: 1rem;
        }

        .link{
            text-decoration: none;
            color: #fff; 
            margin: auto;           
        }
        a:hover{
            font-weight: 700;
        }

        a:after {
            content: '';
            position: absolute;
            width: 40%;
            transform: scaleX(0);
            height: 2px;
            left: 30%;
            
            background-color: rgb(255, 255, 255);
            transform-origin: center;
            transition: transform 0.25s ease-out;
          }

          a:hover:after {
            transform: scaleX(1);
            transform-origin: center;
        }
    }

        .side{
            position: relative;
            
        }

        &.active{
            right: 0;
            background-color:$secondary;
        }


}