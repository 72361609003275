$primary: #0A64BC;
$secondary: #82b6ee;
$secondaryLight:#bad5f2 ;
$primaryDark: #213458 ;
$tertiary: rgb(239, 32, 32);

$breakPointWidth: 948px;
$breakPointHeight: 750px;
$breakPointHeightBig: 950px;


@mixin desktop{
    @media (min-height: #{$breakPointHeight}){
        @content
    }
}


@mixin big{
    @media (min-height: #{$breakPointHeightBig}){
        @content
    }
}



@mixin mobile{
    @media (max-width: #{$breakPointWidth}){
        @content
    }
}

@mixin tiny{
    @media (max-width: #{$breakPointWidth - 500px}){
        @content
    }
}
