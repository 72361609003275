@import '../../global.scss';



.welcome {
    background-color: #fff;
    background-image: url('../../../public/images/5two.svg');
    background-position: 10%;
     background-size: cover;

    background-repeat: no-repeat;
    // border-bottom: 2px solid $secondaryLight;

    @include mobile{
        flex-direction: column;
        align-items: center;

    }
    
    display:flex;
    .left{
        flex: 0.5;
        overflow: hidden;
        

        .image-container{
            position: relative;
            width: 620px;
            height: 620px;
        
            display: flex;
           
            align-items: flex-end;
            justify-content: center;
            float: right;
            // filter: brightness(110%);
            z-index: 10;
            top: 10px;
            

            @include mobile{
                align-items:flex-start ;
               height: 370px;
               width: 370px;
               
               
            }

            @include tiny{
                
                
                top:75px;
            }
            img{
                width: 90%;


            }
        }
    }

    .right{
        position: relative;
        flex: 0.5;

        @include mobile{
            align-items: center;
            margin: 0;
        }
        .wrapper{
            width: 100%;
            height: 100%;
            overflow: hidden;
            
            display:flex;

            flex-direction: column;
            justify-content: center;

            h1{
               
                font-size: 6rem;
                margin-bottom: 1rem;
                @include mobile{
                    font-size: 4rem;
                    padding: 5px;
                }
            }

            h4{
                
                font-size : 4rem;
                margin-bottom: 1rem;
                font-weight: 500;
                @include mobile{
                    font-size: 3rem;
                    padding: 5px;
                    font-weight: 700;
                }
            }

            h3{
                font-size: 2rem;
                margin-bottom: 1rem;
                font-weight: 700;
                @include mobile{
                    font-size: 0rem;
                    padding: 5px;
                    
                }
            }

            span {
                font-size: 2rem;
                color: $secondary;
                @include mobile{
                    font-size: 2rem;
                    
                }
            }
        }
        a{
            position: absolute;
            bottom : 50px;
            display: flex;
            
        }

        .icon{
            
            font-size: 5rem;
            display: flex;
            margin-left: 7rem;
            color: $secondary;
            animation: arrowBounce 2s infinite;
            @include mobile{
                display: none;
            }
        }

        @keyframes arrowBounce{
            100% {
              transform: translateY(0);
            }
            0% {
                transform: translateY(0);
              }
            20% {
                transform: translateY(0);
              }
            50% {
                transform: translateY(0);
              }
            80%  {
                transform: translateY(0);
              }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }

        
    }

    .ityped-cursor {
        
        opacity: 1;
        -webkit-animation: blink 0.7s infinite;
        -moz-animation: blink 0.7s infinite;
        animation: blink 0.7s infinite;
        animation-direction: alternate;
    }
     
    @keyframes blink {
        100% {
            opacity: 0;
        }
    }
     
    @-webkit-keyframes blink {
        100% {
            opacity: 0;
        }
    }
     
    @-moz-keyframes blink {
        100% {
            opacity: 0;
        }
    }
}
}